
.ql-toolbar.ql-snow {
  border-top-left-radius:  0.375rem;
  border-top-right-radius: 0.375rem;
  border-color: #6b7280;
  background-color: rgb(255 255 255 / 0.05);
  color: white;
}

.ql-container.ql-snow {
  border-bottom-left-radius:  0.375rem;
  border-bottom-right-radius: 0.375rem;
  border-color: #6b7280;
  background-color: rgb(255 255 255 / 0.05);
  color: white;
}

.ql-snow .ql-picker-label {
  color: white;
}

.ql-snow .ql-stroke {
  color: white;
  stroke: white;
}

.ql-snow .ql-fill {
  fill: white;
}